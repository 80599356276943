const axios = require('axios');
const MultiloadBackendAdapter = require('i18next-multiload-backend-adapter/cjs');
const HttpBackend = require('i18next-http-backend');
// const ICU  = require('i18next-icu');
const FsBackend = require('i18next-fs-backend');
const ChainBackend = require('i18next-chained-backend');

const isBrowser = typeof window !== 'undefined';

const HttpOptions = {
    backend: HttpBackend,
    // HTTP backend options
    backendOption: {
        loadPath: (languages, namespaces) => {
            const basePath =
                process.env.API_URL || 'https://api.testweb.vpsnet.com';
            return (
                basePath +
                '/public/i18n?lng=' +
                languages.join('+') +
                '&ns=' +
                namespaces.join('+')
            );
        },
        addPath: (lng, namespace) => {
            const basePath =
                process.env.API_URL || 'https://api.testweb.vpsnet.com';
            return basePath + '/public/i18n?lng=' + lng + '&ns=' + namespace;
        },
        allowMultiLoading: false,
        // 'options' will be this entire options object
        // 'url' will be passed the value of 'loadPath'
        // 'payload' will be a key:value object used when saving missing translations
        // 'callback' is a function that takes two parameters, 'err' and 'res'.
        //            'err' should be an error
        //            'res' should be an object with a 'status' property and a 'data' property containing a stringified object instance beeing the key:value translation pairs for the
        //            requested language and namespace, or null in case of an error.
        request: (options, url, payload, callback) => {
            if (typeof payload === 'undefined') {
                axios
                    .get(url)
                    .then((response) => {
                        callback(null, {
                            status: response.status,
                            data: response.data,
                        });
                    })
                    .catch((error) => {
                        if (error instanceof Error) {
                            callback(error.message, null);
                        }
                        callback('Fatal error', null);
                    });
            } else {
                console.log(options, url, payload);
            }
        },
    },
};

/**
 * @type {import('ni18n').Ni18nOptions}
 */
const ni18nConfig = {
    react: {
        useSuspense: false,
        wait: true,
    },
    load: 'currentOnly',
    lowerCaseLng: true,
    locales: ['lt', 'en'],
    defaultLocale: 'en',
    supportedLngs: ['lt', 'en' /*, 'ru'*/],
    fallbackLng: ['en'],
    defaultNS: 'common',
    ns: [
        'errorPage',
        'common',
        'error',
        'validation',
        'extraPaymentsMacroPay',
        'extraPaymentsUserTopup',
        'extraInvoicesMailsConfirm',
        'landingFeaturesReasonsTechnology',
        'landingHelpFaq',
        'landingHome',
        'landingInformationAboutCompany',
        'landingInformationLoyalty',
        'landing',
        'landingNavigation',
        'landingServicesColocation',
        'landingServicesDs',
        'landingServices',
        'landingServicesVds',
        'landingServicesVps',
        'landingContactForm',
        'login',
        'userLoginReset',
        'userAuth',
        'userRegistration',
        'passwordResetConfirm',
        'passwordReset',
        'referrals',
        'registration',
        'resourcesValues',
        'translation',
        'uiAccountLoginHistory',
        'uiAccountNotifications',
        'uiAccountPasswordChange',
        'uiAccountProfile',
        'uiAccountServiceManagementHistory',
        'uiComponent',
        'uiCopyIcon',
        'uiHelpDuk',
        'uiNavigation',
        'uiNoty',
        'uiOrder',
        'uiPartnership',
        'uiPaymentsHistory',
        'uiPaymentsInvoices',
        'uiPaymentsSystems',
        'uiPaymentsTopup',
        'uiServicesBackups',
        'uiServicesDetail',
        'uiServicesGraphs',
        'uiServices',
        'uiServicesMain',
        'uiServicesManagementHistory',
        'uiServicesOsChange',
        'uiServicesRenew',
        'uiServicesResourceChange',
        'uiServicesSettings',
        'uiServicesTransfer',
        'uiAccountTwoAuth',
        'uiAccountEmailChange',
        'uiComponentsTwoAuth',
        'uiAccountSocialAccounts',
        'uiComponentsTableInfinity',
        'uiServicesMassActionRenew',
    ],
    use: isBrowser ? [MultiloadBackendAdapter] : [ChainBackend],
    backend: isBrowser
        ? [HttpOptions]
        : {
              backends: [FsBackend, MultiloadBackendAdapter],
              backendOptions: [
                  {
                      expirationTime: 1 * 60 * 60 * 1000, // 1 hour
                      loadPath: (lng, ns) =>
                          `./locales_cache/${lng}/${ns}.json`,
                      addPath: (lng, ns) => `./locales_cache/${lng}/${ns}.json`, // make sure the folders "locales_cache/{{lng}}" exists
                  },
                  HttpOptions,
              ],
          },
    // debug: process.env.NODE_ENV === 'development',
    debug: false,
    strictMode: process.env.NODE_ENV === 'production',
    interpolation: {
        escapeValue: false, // react already safes from xss
        prefix: '[[',
        suffix: ']]',
    },
    detection: {
        lookupCookie: 'next-i18next',
        order: ['cookie', 'header', 'querystring'],
        caches: ['cookie'],
    },
};

module.exports = ni18nConfig;
